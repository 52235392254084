






import { Component, Vue } from 'vue-property-decorator'
import Search from '../components/Search.vue'
import EditJobsTable from '../components/EditJobsTable.vue'
import { ImageEntry, userHasRoles, UserRoles } from '@ht-lib/accounts-models'

@Component({
  name: 'Editing',
  components: { Search, EditJobsTable }
})
export default class extends Vue {
  created () {
    if (!userHasRoles(this.$auth.user.profile, [UserRoles.SOFTWARE_GODS, UserRoles.IMAGE_EDITING, UserRoles.IMAGE_EDITING_ADMIN])) {
      this.$router.push('/')
    }
  }

  search (x: ImageEntry[]) {
    console.log('Search', x)
  }
}
