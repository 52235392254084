

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { OnSearch, SearchType } from '../ts/search'
import { Market } from '@ht-lib/accounts-models'

const defaultSearch = process.env.NODE_ENV === 'production' ? '' : '380177/0092'
@Component({ name: 'Search' })
export default class extends Vue {
  @Prop({ type: Function, required: true }) onSearch: OnSearch
  input = defaultSearch
  type: SearchType = SearchType.JOB
  market: Market = 'S'
  typeOptions = Object.values(SearchType)
  options = [
    { value: 'S', label: 'Schools' },
    { value: 'G', label: 'Grads' }
  ]

  isLoading = false

  search () {
    this.isLoading = true
    if (this.type !== SearchType.JOB_DOC_ID) {
      this.input = this.input.replace('/', '-').toUpperCase()
    }
    this.onSearch(this.input, this.market, this.type)
    this.isLoading = false
  }
}
