import { ImageEntry, Market } from '@ht-lib/accounts-models'
import { Image } from '@ht-web/firebase-adapter'
import { splitAimsJobNumber } from './images'

export enum SearchType {
  JOB = 'Aims Job Number',
  GUID = 'Guid',
  JOB_DOC_ID = 'Job Id'
}

export type OnSearch = (searchTerm: string, market: Market, searchType: SearchType) => void

export async function searchImages (market: Market, query: string, type: SearchType): Promise<ImageEntry[] | null> {
  if (type === SearchType.JOB) {
    const [accountCode, jobCode] = splitAimsJobNumber(query)
    if (!accountCode || !jobCode) {
      return null
    }

    return await Image.byAimsJob(accountCode, jobCode).multiple()
  }

  if (type === SearchType.JOB_DOC_ID) {
    return await Image.byJobId(query).multiple()
  }

  if (type === SearchType.GUID) {
    if (query.length !== 10) {
      return null
    }

    return [await Image.byRef(market, query).single()]
  }

  return null
}
