













































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { activeEditJobsQuery, searchAll, updateEditJob } from '../ts/editing'
import { EditJob, EditJobPriority, EditJobStatus, editPriorityValue } from '@ht-lib/accounts-models'
import EditJobModal from './EditJobModal.vue'
import { getUserWithStore, UserProfileMap } from '../ts/userStore'
import { format, fromUnixTime } from 'date-fns'

const marketLookup = { S: 'Schools', G: 'Grads' }

@Component({
  name: 'EditJobsTable',
  components: {
    EditJobModal
  }
})
export default class extends Vue {
  @Ref() editModal: EditJobModal

  snap !: () => void
  jobs: EditJob[] = []
  loading = true
  users: UserProfileMap = {}
  searchTerm = ''
  loadingSearch = false
  searching = false

  assignedUser (job: EditJob) {
    return this.users[job.assignedUserId]?.displayName || 'Unassigned'
  }

  createdUser (job: EditJob) {
    if (job.createdUser === 'SYSTEM') return job.createdUser
    return this.users[job.createdUser]?.displayName || 'Not Found'
  }

  showEditJobModal (job: EditJob | null) {
    this.editModal.showModal(job)
  }

  async openJob (job: EditJob) {
    if (!job.assignedUserId || job.status === EditJobStatus.UNASSIGNED) {
      await updateEditJob(job.id, { assignedUserId: this.$auth.user.uid, status: EditJobStatus.IN_PROGRESS })
    }
    if (job.assignedUserId === this.$auth.user.uid && job.status === EditJobStatus.ASSGINED) {
      await updateEditJob(job.id, { status: EditJobStatus.IN_PROGRESS })
    }
    this.$router.push(`/editing/view/${ job.id }`)
  }

  marketString (job: EditJob) {
    return marketLookup[job.market]
  }

  createdDate (job: EditJob) {
    return format(fromUnixTime(job.createTime), 'HH:mm - dd MMM')
  }

  clearSearch () {
    this.setupSnap()
    this.searching = false
  }

  async search () {
    this.cleanup()
    this.loadingSearch = true
    const docs = await searchAll(this.searchTerm)
    this.setJobs(docs)
    console.log(docs)
    this.loadingSearch = false
    this.searching = true
  }

  async setJobs (jobs: EditJob[]) {
    this.jobs = jobs.sort((a, b) => {
      return (editPriorityValue(b) - editPriorityValue(a)) || a.createTime - b.createTime
    })

    const map: UserProfileMap = {}
    await Promise.all(
      this.jobs.map(async x => {
        if (x.createdUser && x.createdUser !== 'SYSTEM') {
          map[x.createdUser] = await getUserWithStore(x.createdUser)
        }
        if (x.assignedUserId) {
          map[x.assignedUserId] = await getUserWithStore(x.assignedUserId)
        }
      })
    )
    this.users = map
    this.loading = false
  }

  setupSnap () {
    this.cleanup()
    this.snap = activeEditJobsQuery().onSnapshot(snap => {
      this.setJobs(snap.docs.map(x => x.data()))
    })
  }

  cleanup () {
    if (this.snap) {
      this.snap()
    }
  }

  created () {
    this.setupSnap()
  }

  beforeDestroy () {
    this.cleanup()
  }

  jobRowColour (job: EditJob) {
    if (job.status === EditJobStatus.COMPLETE) {
      return 'bg-green-11'
    }
    if (job.priority === EditJobPriority.URGENT) {
      return 'bg-red-11'
    }
    if (job.priority === EditJobPriority.PRIORITY) {
      return 'bg-yellow-11'
    }
    return ''
  }
}
